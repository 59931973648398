import React, {useEffect, useState} from "react";
import styles from './StoragePopup.module.scss';
import StorageItem from "../../../Common/StorageItem/StorageItem";
import {Pagination} from "@mui/material";
import SearchStorage from "../../../Common/SearchStorage/SearchStorage";
import Preloader from "../../../Common/Preloader/Preloader";
import { getAccessToken } from "../../../../utils/authTokens";
import { getStorageProducts } from "../../../../api/productsApi";

const StoragePopup = ({orderId, setOrder, setPopup}) => {
    const [storageList, setStorageList] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [searchValue, setSearchValue] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (storageList && storageList.length === 0) {
            const accessToken = getAccessToken();
            setIsLoading(true);
            getStorageProducts(accessToken, 1).then((res) => {
                if (res?.items && res.items.length > 0) {
                    setStorageList(res.items)
                }

                if (res?.total_pages) {
                    setTotalPages(res.total_pages)
                }
            }).finally(() => {
                setIsLoading(false);
            })
        }
    }, [])

    const handlePaginationChange = (event, value) => {
        if (page !== value) {
            setPage(value);
            window.scrollTo({top: 0, behavior: 'auto'});
            const accessToken = getAccessToken();
            getStorageProducts(accessToken, value).then((res) => {
                if (res?.items && res.items.length > 0) {
                    setStorageList(res.items)
                }
            })
        }
    }

    return (
        <div className={styles.storage}>
            <div className={styles.storage__search}>
                <SearchStorage searchValue={searchValue} setSearchValue={setSearchValue} setPage={setPage}
                               setTotalPages={setTotalPages} setStorageList={setStorageList}/>
            </div>
            <div className={styles.storage__list}>
                {storageList && storageList.length > 0 && storageList.map(({id, name, images, sizes}) =>
                    <StorageItem
                        key={id}
                        orderId={orderId}
                        id={id}
                        name={name}
                        images={images}
                        sizes={sizes}
                        setOrder={setOrder}
                        setPopup={setPopup}
                    />)}
                {totalPages > 1 && (
                    <Pagination count={totalPages} page={page} siblingCount={1} boundaryCount={1} hidePrevButton
                                hideNextButton onChange={handlePaginationChange}/>
                )}
            </div>
            {isLoading && <Preloader />}
        </div>
    )
}

export default StoragePopup;