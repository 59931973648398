import React, {useEffect, useState} from "react";
import styles from './Calculator.module.scss';
import FabricType from "./FabricType/FabricType";
import FabricSize from "./FabricSize/FabricSize";
import FabricOptions from "./FabricOptions/FabricOptions";
import FabricSet from "./FabricSet/FabricSet";
import CalcButton from "./CalcButton/CalcButton";
import FabricPrice from "./FabricPrice/FabricPrice";
import { getAccessToken } from "../../../utils/authTokens";
import { getFabricTemplate, setKit, setSplitKit, setUpdateKit } from "../../../api/calculatorApi";
import {useAppDispatch} from "../../../hooks/redux";
import {setIsActivePopup} from "../../../store/main-slice";
import GlobalPopup from "../../Common/GlobalPopup/GlobalPopup";
import FabricSizeSecond from "./FabricSize/FabricSizeSecond";
import AddCommentButton from "./CommentButton/AddCommentButton";
import KitCommentPopup from "./Popups/CommentPopup/KitCommentPopup";
import AddComponentButton from "./ComponentButton/AddComponentButton";
import ComponentsPopup from "./Popups/ComponentsPopup/ComponentsPopup";
import {useParams} from "react-router-dom";
import {useNavigate} from "react-router";
import Preloader from "../../Common/Preloader/Preloader";
import GlobalCalculator from "../../Common/GlobalCalculator/GlobalCalculator";

const Calculator = () => {
    const {orderId, id} = useParams();
    const navigate = useNavigate();
    const [showKitCommentPopup, setShowKitCommentPopup] = useState(false);
    const [showComponentPopup, setShowComponentPopup] = useState(false);
    const [showFabricAmountPopup, setShowFabricAmountPopup] = useState(false);
    const [template, setTemplate] = useState(null);
    const [activeSecondSize, setActiveSecondSize] = useState(null);
    const [size, setSize] = useState(null);
    const [customTemplateSize, setCustomTemplateSize] = useState(null);
    const [activeSize, setActiveSize] = useState(() =>
        !!id ? {index: null, standard: 1} : {index: null, standard: 0}
    );
    const [components, setComponents] = useState(null);
    const [fabric, setFabric] = useState(null);
    const dispatch = useAppDispatch();
    const [product, setProduct] = useState(null);
    const [form, setForm] = useState(null);
    const [kitCommentText, setKitCommentText] = useState("");
    const [customComponents, setCustomComponents] = useState([]);
    const [selectedOptionIds, setSelectedOptionIds] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [fabricConsumption, setFabricConsumption] = useState(0);
    const [componentSizes, setComponentSizes] = useState([]);
    const [isKitSplitted, setIsKitSplitted] = useState(false);

    useEffect(() => {
        if (fabric && size) {
            const accessToken = getAccessToken();
            setIsLoading(true);
            getFabricTemplate(accessToken, fabric.id, size.id).then((resp) => {
                if (resp && resp.kit) {
                    setProduct(resp)
                } else {
                    setForm(null);
                    setProduct(null);
                    setComponents(null);
                    setCustomComponents([]);
                    setKitCommentText("");
                    setSelectedOptionIds([])
                }
            }).finally(() => {
                setIsLoading(false);
            })
        }

    }, [fabric, size])

    const calcSave = () => {
        const isEdit = !!id;
        if (!isEdit) {
            const updatedForm = {
                ...form,
                comment: kitCommentText,
                additional_components: customComponents.map(item => item.id),
                option: [...selectedOptionIds],
                custom_size: customTemplateSize,
                additional_fabric_consumption_count: fabricConsumption,
                is_split: isKitSplitted
            };
            setForm(updatedForm);
            const accessToken = getAccessToken();
            setKit(accessToken, updatedForm).then((res) => {
                if (res?.order_product_id) {
                    window.location.href = `/calculator/${orderId}/${res.order_product_id}`;
                } else {
                    console.log(res);
                    navigate(`products/${orderId}`);
                }
            });
        } else {
            const updatedForm = {
                ...form,
                comment: kitCommentText,
                additional_components: customComponents.map(item => item.id),
                option: [...selectedOptionIds],
                custom_size: customTemplateSize,
                additional_fabric_consumption_count: fabricConsumption
            };
            setForm(updatedForm);

            const formattedEditForm = {};

            const templateComponents = {...updatedForm}.template_components.map((el) => {
                return {
                    id: el.component_template_id,
                    custom_size : el?.custom_size === undefined ? null : el?.custom_size,
                    option: el.option,
                    comment: el?.comment
                }
            });
            formattedEditForm.component = templateComponents;
            formattedEditForm.additional_components = customComponents.map(item => item.id);
            formattedEditForm.custom_size = customTemplateSize;
            formattedEditForm.comment = kitCommentText;
            formattedEditForm.option = [...selectedOptionIds];
            formattedEditForm.additional_fabric_consumption_count = fabricConsumption;
            formattedEditForm.is_split = isKitSplitted;
            const accessToken = getAccessToken();
            setUpdateKit(accessToken, formattedEditForm, product?.kit[0].id).then((response) => {
                if (response?.order_product_id) {
                    setComponents(response.kit[0]);
                    setCustomComponents([]);
                    const componentSizes = response.kit[0].components.map(component => ({
                        sizes: [{
                            id: component.id,
                            name: component.name,
                            price_retail: component.price_retail,
                            size: component.size.name,
                            image: component.image
                        }],
                        selected: 0
                    }));
                    setComponentSizes(componentSizes);
                } else {
                    console.log(response);
                    navigate(`products/${orderId}`);
                }
            })
        }
    }

    const openKitCommentPopup = () => {
        setShowKitCommentPopup(true);
        dispatch(setIsActivePopup(true));
    }

    const onSaveKitComment = () => {
        setShowKitCommentPopup(false);
        dispatch(setIsActivePopup(false));
    }

    const openComponentPopup = () => {
        setShowComponentPopup(true);
        dispatch(setIsActivePopup(true));
    }

    const openFabricConsumptionPopup = () => {
        setShowFabricAmountPopup(true);
        dispatch(setIsActivePopup(true));
    }

    const handleFabricConsumptionDelete = () => {
        let newValue = Math.floor(fabricConsumption / 10);
        setFabricConsumption(newValue);
    }

    const handleFabricConsumptionChange = (e) => {
        const value = e.target.textContent;
        const fabricString = String(fabricConsumption);
        const newFabricValue = Number(fabricString + value);
        if (fabricString.length < 4) {
            setFabricConsumption(newFabricValue);
        }
    }

    const addCustomComponent = (newComponent) => {
        setCustomComponents(prevComponents => [...prevComponents, newComponent]);
        setShowComponentPopup(false);
        dispatch(setIsActivePopup(false));
    }

    const splitKit = () => {
        const accessToken = getAccessToken();
        setIsLoading(true);
        setSplitKit(accessToken, components.id).then(resp => {
            if (resp?.length > 0) {
                const additionalSizes = resp.map(components => {
                    return components.components.map(component => ({
                        id: component.id,
                        name: component.name,
                        price_retail: component.price_retail,
                        size: component.size,
                        image: component.image
                    }))
                });
                const newComponentSizes = componentSizes.map((prevComponent, idx) => ({
                    ...prevComponent,
                    sizes: [...prevComponent.sizes, ...additionalSizes[idx]]
                }));
                setComponentSizes(newComponentSizes);
                setIsKitSplitted(true);
            }
        }).finally(() => {
            setIsLoading(false);
        })
    }

    const handleSelectSize = (componentIndex, sizeIndex) => {
        const newComponentSizes = componentSizes.map((component, idx) => ({
            ...component,
            selected: componentIndex === idx ? sizeIndex : component.selected
        }));
        setComponentSizes(newComponentSizes);
        setForm(prevForm => ({
            ...prevForm,
            template_components: prevForm.template_components.map((component, idx) => ({
                ...component,
                component_template_id: idx === componentIndex ? newComponentSizes[idx].sizes[sizeIndex].id : component.component_template_id
            }))
        }))
    }

    const resetKitValues = () => {
        setComponents(prevComponents => ({ ...prevComponents, components: [] }));
        setForm(prevForm => ({ ...prevForm, template_components: [] }));
        setComponentSizes([]);
        setCustomComponents([]);
        setActiveSecondSize(null);
        setIsKitSplitted(false);
    }

    const handleFabricTypeChange = () => {
        resetKitValues();
        const initialActiveSize = !!id ? {index: null, standard: 1} : {index: null, standard: 0};
        setActiveSize(initialActiveSize);
    }

    return (
        <div className={styles.calculator}>
            <h2>Тип тканини</h2>
            <FabricType setTemplate={setTemplate} fabric={fabric} setFabric={setFabric} setComponents={setComponents}
                        id={id} setProduct={setProduct} setKitCommentText={setKitCommentText} setForm={setForm}
                        isEdit={!!id} isLoading={isLoading} setIsLoading={setIsLoading} setFabricConsumption={setFabricConsumption}
                        setComponentSizes={setComponentSizes} setIsKitSplitted={setIsKitSplitted}
                        onFabricTypeChange={handleFabricTypeChange}/>
            <h2>Розмір комплекту</h2>
            <FabricSize activeSize={activeSize} setActiveSize={setActiveSize} kitTemplateSizes={template?.kit_template_size}
                        setKitSize={setSize} setCustomTemplateSize={setCustomTemplateSize} setKitCommentText={setKitCommentText}
                        isEdit={!!id} onSizeChange={resetKitValues}/>
            {product && product.kit && (
                <FabricSizeSecond
                    activeSecondSize={activeSecondSize}
                    setActiveSecondSize={(idx) => {
                        setActiveSecondSize(idx);
                        setIsKitSplitted(false);
                    }}
                    orderId={orderId}
                    setComponents={setComponents}
                    setForm={setForm}
                    product={product}
                    isEdit={!!id}
                    setFabricConsumption={setFabricConsumption}
                    setComponentSizes={setComponentSizes}
                />
            )}
            {components && (
                <>
                    {!isKitSplitted && !id && components?.components?.length > 0 && (
                        <div className={styles.kitSplitButton}>
                            <button onClick={splitKit}>Розформувати комплект</button>
                        </div>
                    )}
                    {components?.components?.length > 0 && (
                        <>
                            <h2 className={styles.setTitle}>Комплект</h2>
                            <FabricSet components={components.components} setComponents={setComponents}
                                    customComponents={customComponents} setCustomComponents={setCustomComponents}
                                    kitId={components.id} types={fabric?.mono_fabric_type} form={form} setForm={setForm}
                                    isEdit={!!id} componentSizes={componentSizes} onSelectSize={handleSelectSize}
                                    isKitSplitted={isKitSplitted}/>
                        </>
                    )}
                    <div className={styles.bottomKitButtons}>
                        <AddCommentButton onClick={openKitCommentPopup}/>
                        <AddComponentButton onClick={openComponentPopup}/>
                    </div>
                </>
            )}
            {product && product.kit_options && (
                <>
                    <h2>Опції</h2>
                    <FabricOptions
                        options={product.kit_options}
                        selectedOptionIds={selectedOptionIds}
                        setSelectedOptionIds={setSelectedOptionIds}
                        openFabricConsumptionPopup={openFabricConsumptionPopup}
                        fabricConsumption={fabricConsumption}
                    />
                </>
            )}
            <CalcButton
                action={calcSave}
                isDisabled={!components || (components?.components?.length === 0 && customComponents?.length === 0)}
                buttonName="Обрахувати і Зберегти"
            />
            {components?.price_retail && !!id && (
                <FabricPrice price={components.price_retail}/>
            )}
            {showKitCommentPopup && (
                <GlobalPopup topPopup title={'Коментар'} onClose={() => setShowKitCommentPopup(false)}>
                    <KitCommentPopup kitCommentText={kitCommentText} setKitCommentText={setKitCommentText}
                                     onSaveKitComment={onSaveKitComment}/>
                </GlobalPopup>
            )}
            {showComponentPopup && (
                <GlobalPopup topPopup title={'Комплектуючі:'} onClose={() => setShowComponentPopup(false)}>
                    <ComponentsPopup addCustomComponent={addCustomComponent}/>
                </GlobalPopup>
            )}
            {showFabricAmountPopup && (
                <GlobalPopup title={'Витрата тканини:'} onClose={() => setShowFabricAmountPopup(false)}>
                    <GlobalCalculator handleChange={handleFabricConsumptionChange} handleDelete={handleFabricConsumptionDelete} onSubmit={() => setShowFabricAmountPopup(false)}>
                        <div className={styles.fabricInputWrap}>
                            <input id='fabric_amount' name="fabric_amount" value={fabricConsumption}
                                    className={'baseInput'} type="text" readOnly/><span>&nbsp;см</span>
                        </div>
                    </GlobalCalculator>
                </GlobalPopup>
            )}
            {isLoading && <Preloader />}
        </div>
    )
}

export default Calculator;