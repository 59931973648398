import React from "react";
import styles from './FabricSet.module.scss';
import FabricSetItem from "./FabricSetItem/FabricSetItem";
import { removeKitComponent } from "../../../../api/calculatorApi";
import { getAccessToken } from "../../../../utils/authTokens";

const FabricSet = ({
    components,
    setComponents,
    customComponents,
    setCustomComponents,
    kitId,
    types,
    form,
    setForm,
    isEdit,
    componentSizes,
    onSelectSize,
    isKitSplitted
}) => {

    const onChangeOptions = (value, index, type, detail) => {
        const newObj = { ...form };
        const component = newObj.template_components[index];

        if (!component) {
            return;
        }

        if (type === 'option') {
            if (!detail) {
                if (component.option.includes(value)) {
                    component.option = component.option.filter((el) => el !== value);
                } else {
                    component.option.push(value);
                }
                setForm({ ...newObj });
            } else if (detail === 'option_array') {
                component.option = value
            }
        } else if (type === 'comment') {
            component.comment = value;
        } else if (type === 'custom_size') {
            component.custom_size = value;
        }
    }

    const handleDeleteComponent = (idx, itemId) => {
        if (isEdit) {
            const accessToken = getAccessToken();
            removeKitComponent(accessToken, kitId, itemId).then(result => {
                if (result && result.kit && result.kit.length > 0) {
                    setComponents(prevComponents => ({ ...prevComponents, components: result.kit[0].components }));
                    const newTemplateComponents = form.template_components.filter((item) => item.component_template_id !== itemId);
                    setForm(prevForm => ({ ...prevForm, template_components: newTemplateComponents }));
                } else {
                    console.log(result);
                }
            })
        } else {
            const newComponents = components.filter((_, index) => index !== idx);
            setComponents(prevKit => ({ ...prevKit, components: newComponents }));
            setForm(prevForm => ({
                ...prevForm,
                template_components: prevForm.template_components.filter((_, index) => index !== idx)
            }));
        }
    }

    const handleDeleteCustomComponent = (idx) => {
        const newCustomComponents = customComponents.filter((_, index) => index !== idx);
        setCustomComponents(newCustomComponents);
    }

    return (
      <div>
            {components?.length === 0 && customComponents?.length === 0 && (
                <p className={styles.warning}>Очікуємо доступні комплекти</p>
            )}
            {Array.isArray(components) && components.length > 0 && (
                components.map((el, index) => (
                    <FabricSetItem
                        componentIndex={index}
                        item={el}
                        key={index}
                        types={types}
                        onChangeOptions={onChangeOptions}
                        form={form}
                        onDelete={handleDeleteComponent}
                        isEdit={isEdit}
                        componentSize={componentSizes[index]}
                        onSelectSize={onSelectSize}
                        isKitSplitted={isKitSplitted}
                    />
                ))
            )}
            {Array.isArray(customComponents) && customComponents.length > 0 && (
                customComponents.map((el, index) => (
                    <FabricSetItem
                        componentIndex={index}
                        item={el}
                        key={index}
                        types={types}
                        onChangeOptions={onChangeOptions}
                        form={form}
                        onDelete={handleDeleteCustomComponent}
                        isEdit={isEdit}
                    />
                ))
            )}
      </div>
    )
}

export default FabricSet