import React, {useEffect, useState} from "react";
import styles from './Status.module.scss';
import logo from "../../../assets/img/logoEgo.svg";
import Details from "./Details/Details";
import InvoiceInfo from "./InvoiceInfo/InvoiceInfo";
import List from "./List/List";
import Price from "./Price/Price";
import Payment from "./Payment/Payment";
import PaymentInfo from "./PaymentInfo/PaymentInfo";
import Link from "./Link/Link";
import Preloader from "../../Common/Preloader/Preloader";
import { sendOrderHash } from "../../../api/ordersApi";
import { getReceipt } from "../../../api/receiptApi";
import { setReceipt as sendReceipt } from "../../../api/receiptApi";

const Status = ({ orderHash }) => {
    const [order, setOrder] = useState(null);
    const [warehouseItems, setWarehouseItems] = useState([]);
    const [receipt, setReceipt] = useState(null);
    const [isFileUploading, setIsFileUploading] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (orderHash) {
            sendOrderHash(orderHash).then((response) => {
                if (response?.order) {
                    setOrder(response)
                }
                if (response?.products) {
                    const warehouse_items = response.products.map(product => ({ warehouse_item: product }));
                    const kit = response.kits.map(kit => ({ kit: kit }));
                    setWarehouseItems(() => [ ...warehouse_items, ...kit ]);
                }
                if (response?.payment?.id) {
                    getReceipt(response.payment.id).then(res => {
                        if (res?.length > 0) {
                            setReceipt(res[res.length - 1]);
                        } else if (res?.id) {
                            setReceipt(res);
                        }
                    }).finally(() => {
                        setIsLoading(false);
                    })
                } else {
                    setIsLoading(false);
                }
            }).catch(err => {
                console.log(err);
                setIsLoading(false);
            })
        } else {
            setIsLoading(false);
        }
    }, [orderHash]);

    const onReceiptUpload = (file) => {
        const formData = new FormData();
        formData.append("payment", +order?.payment?.id);
        formData.append("receipt", file);

        setIsFileUploading(true);
        sendReceipt(formData).then(res => {
            if (res?.id) {
                setReceipt(res);
            }
        }).finally(() => {
            setIsFileUploading(false);
        })
    }

    return (
        <>
            {!isLoading && order && (
                <div className={styles.status}>
                    <div className={styles.status__logo}>
                        <img src={logo} alt=""/>
                    </div>
                    {order && (
                        <h2>Замовлення № {order.order.id}</h2>
                    )}
                    {order && (
                        <p className={styles.status__date}>
                            {order.order.created.substring(8, 10)}.{order.order.created.substring(5, 7)}.{order.order.created.substring(0, 4)}
                        </p>
                    )}
                    <Details orderId={order?.order?.id} />
                    <InvoiceInfo order={order} />
                    <List products={warehouseItems}/>
                    <Price order={order}/>
                    {order?.payment?.status === "PAY_WAIT" && (
                        <>
                            <Payment
                                orderId={order?.order?.id}
                                paymentId={order?.payment?.id}
                                isDisabled={(order?.products?.length === 0 && order?.kits?.length === 0) || !order?.payment?.final_amount}
                            />
                            <PaymentInfo
                                receipt={receipt}
                                onReceiptUpload={onReceiptUpload}
                                isUploading={isFileUploading}
                            />
                        </>
                    )}
                    {receipt?.receipt && (
                        <Link receiptUrl={receipt.receipt}/>
                    )}
                </div>
            )}
            {isLoading && <Preloader/>}
            {!isLoading && !order && (
                <div className={styles.notFound}>
                    <h2 className={styles.message}>Замовлення не знайдено, зверніться до вашого менеджера Ego-textile для уточнення інформації.</h2>
                </div>
            )}
        </>
    )
}

export default Status;