import React, {useEffect, useMemo, useState} from "react";
import styles from './OrderStatus.module.scss';
import OrderInfoBlock from "../../Common/OrderInfoBlock/OrderInfoBlock";
import ProductItem from "../../Common/ProductItem/ProductItem";
import OrderActions from "./OrderActions";
import {useAppDispatch} from "../../../hooks/redux";
import PriceInfo from "../../ClientPage/Status/Price/Price";
import Preloader from "../../Common/Preloader/Preloader";
import { useParams } from "react-router-dom";
import NotFound from "../../Common/NotFound/NotFound";
import { setIsNotFound } from "../../../store/main-slice";
import { getAccessToken } from "../../../utils/authTokens";
import { getDeliverNP } from "../../../api/novaPoshtaApi";
import { getCartOrder, removeProduct } from "../../../api/productsApi";
import { getOrderInfo } from "../../../api/ordersApi";
import { getReceipt } from "../../../api/receiptApi";
import { updatePayment } from "../../../api/paymentApi";
import { getFormattedDate } from "../../../utils/getFormattedDate";

const OrderStatus = () => {
    const {id: orderId} = useParams();
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const [order, setOrder] = useState(null);
    const [warehouseItems, setWarehouseItems] = useState([]);
    const [deliveryInfo, setDeliveryInfo] = useState(null);
    const [receipt, setReceiptUrl] = useState(null);

    const getOrderStatusName = (orderStatus) => {
        switch (orderStatus) {
            case 'PAID': return 'Оплачено';
            case 'IN_PROG': return 'В обробці';
            case 'PAY_WAIT': return 'Очікує оплати'
            case 'NOT_PAID': return 'Не оплачено';
            default:
                console.log(orderStatus)
                break;
        }
        return orderStatus
    }

    const totalPrice = useMemo(() => {
        if (order?.id && warehouseItems.length > 0) {
            const accessToken = getAccessToken();
            const result = warehouseItems.reduce((sum, {warehouse_item, kit}) => sum + (warehouse_item ? warehouse_item.price : kit.price_retail), 0);
            updatePayment(accessToken, order.id, {'amount': result}).then((res) => console.log(res))
            return result;
        }
    }, [order?.id, warehouseItems])

    const handleSaveFinalPrice = (finalPrice) => {
        const accessToken = getAccessToken();
        updatePayment(accessToken, order.id, {'final_amount': finalPrice}).then((res) => console.log(res))
    }

    useEffect(() => {
        let accessToken = getAccessToken();
        getOrderInfo(accessToken, orderId).then((response) => {
            if (response && response.id) {
                setOrder(response);
                setIsLoading(true);
                accessToken = getAccessToken();
                getCartOrder(accessToken, orderId).then((response) => {
                    if (response && response.length > 0) {
                        setWarehouseItems(response);
                    }
                }).finally(() => {
                    setIsLoading(false);
                })
                if (response?.payment?.id) {
                    getReceipt(response.payment.id).then(res => {
                        if (res?.length > 0 && res[res.length - 1]) {
                            setReceiptUrl(res[res.length - 1]);
                        }
                    })
                }
            } else {
                setIsLoading(false);
                dispatch(setIsNotFound(true));
            }
        }).catch(err => {
            console.log(err);
            setIsLoading(false);
        })
        getDeliverNP(accessToken, orderId).then((result) => {
            if (result && result.length > 0) {
                setDeliveryInfo(result[0])
            }
        })
    }, [])

    const handleDeleteItem = (id) => {
        let accessToken = getAccessToken();
        removeProduct(accessToken, id).then(result => {
            if (result === 204) {
                const newItems = warehouseItems.filter(item => item.id !== id);
                setWarehouseItems(newItems);
                if (newItems.length === 0) {
                    accessToken = getAccessToken();
                    updatePayment(accessToken, orderId, { final_amount: null, amount: null, status: 'NONE' }).then((res) => {
                        if (res.id) {
                            setOrder(prevOrder => ({ ...prevOrder, payment: res }));
                        }
                    })
                }
            }
        })
    }

    const numberEmoji = (num) => num.toString().split("").map(digit => `${digit}️⃣`).join("");

    const onCopyAll = async () => {
        let resultText = '';
        let count = 1;
        resultText += `📝 Замовлення №${orderId}\n`;
        resultText += `📅 Дата замовлення: ${getFormattedDate(order.created)}\n`;
        resultText += '\n';
        if (deliveryInfo?.receiver?.address) {
            resultText += `📍 Адреса доставки: м. ${deliveryInfo.receiver.delivery_receiver.city}, ${deliveryInfo.receiver.address}\n`;
        }
        if (deliveryInfo?.delivery?.ttn) {
            resultText += `📦 ТТН: ${deliveryInfo.delivery.ttn}\n`;
        }
        resultText += '\n\n';
        resultText += '🛒 Список товарів:\n\n';
        warehouseItems.forEach(item => {
            if (item.kit) {
                resultText += `${numberEmoji(count)} Кастомний комплект\n`;
                resultText += `Код тканини: ${item.kit.fabric}\n`;
                resultText += `Тип комплекту: ${item.kit.type}\n`;
                if (item.kit.component.length > 0) {
                    resultText += `Склад комплекту:\n`;
                    resultText += '- ' + item.kit.component.map(component => component.name).join('\n- ')
                    resultText += '\n';
                }
                if (item.kit.option.length > 0) {
                    resultText += `Додаткові опції:\n`;
                    resultText += `- ` + item.kit.option.map(option => option.description).join('\n- ');
                    resultText += '\n';
                }
                resultText += `Ціна: ${item.kit.price_retail} грн\n`;
            } else {
                resultText += `${numberEmoji(count)} ${item.warehouse_item.name}\n`;
                resultText += `Тип: Товар зі складу\n`;
                resultText += `Розмір: ${item.warehouse_item.size}\n`;
                resultText += `Колір: ${item.warehouse_item.color}\n`;
                resultText += `Ціна: ${item.warehouse_item.price} грн\n`;
            }
            resultText += '\n';
            count++;
        });
        resultText += '\n';
        if (order?.comment) {
            resultText += '💬 Коментар до замовлення:\n';
            resultText += order.comment + '\n\n';
        }
        if (totalPrice) {
            if (order?.payment?.discount_amount) {
                const { discount_amount, discount_type } = order.payment;
                const discountValue = discount_type === 'PERC' ? discount_amount + '%' : discount_amount + ' грн';
                resultText += `💰 Ціна зі знижкою ${discountValue} складає: ${totalPrice} грн\n`;
            } else {
                resultText += `💰 Ціна: ${totalPrice} грн\n`;
            }
        }
        resultText += '🔗 Посилання на замовлення:\n';
        resultText += order.url;
        try {
            await navigator.clipboard.writeText(resultText);
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <>
            {!isLoading && order && (
                <div className={styles.orderStatus}>
                    {order && order.id && (
                        <h2>Замовлення № {order.id}</h2>
                    )}
                    <OrderInfoBlock url={order?.customer?.url} departmentNumber={deliveryInfo?.receiver?.department_number} ttn={deliveryInfo?.delivery?.ttn}/>
                    {order?.payment?.status && order?.payment?.status !== 'NONE' && (
                        <div className={styles.statusBar}>
                            <span>{getOrderStatusName(order.payment.status)}</span>
                        </div>
                    )}
                    {warehouseItems && warehouseItems.length > 0 && warehouseItems.map((el, index) => (
                        <ProductItem
                            item={el}
                            key={index}
                            isDisabled={order?.payment?.status === 'PAID' || order?.payment?.status === 'PAY_WAIT'}
                            onDelete={handleDeleteItem}
                        />
                    ))}
                    <OrderActions
                        orderUrl={order?.url}
                        isDisabled={order?.payment?.status === 'PAY_WAIT'}
                        receiptUrl={receipt?.receipt ?? ''}
                        onCopyAll={onCopyAll}
                    />
                    <PriceInfo order={order} totalPrice={totalPrice} setFinalPrice={handleSaveFinalPrice}/>
                    <br/>
                    <br/>
                    {isLoading && (
                        <Preloader />
                    )}
                </div>
            )}
            {isLoading && <Preloader />}
            {!isLoading && !order && (
                <NotFound message={`Замовлення №${orderId} не знайдено`} />
            )}
        </>
    )
}

export default OrderStatus;