import React, {useEffect, useState} from "react";
import styles from './Order.module.scss';
import paste from '../../../assets/icons/pasteIcon.svg';
import {useFormik} from 'formik';
import PayCharger from "./PayCharger";
import DateTimeField from "../DateTimeField/DateTimeField";
import StatusPay from "./StatusPay";
import DiscountField from "./DiscountField";
import UrgentlyField from "./UrgentlyField";
import {useAppSelector} from "../../../hooks/redux";
import {getUser} from "../../../store/selectors";
import Parser from "../../Common/Parser/Parser";
import InputMask from "react-input-mask";
import TotalPrice from "../Products/TotalPrice/TotalPrice";
import { getAccessToken } from "../../../utils/authTokens";
import { updateCustomer } from "../../../api/customersApi"
import { updatePayment } from "../../../api/paymentApi";
import { updateOrder } from "../../../api/ordersApi";

const OrderCreate = ({order}) => {
    const manager = useAppSelector(getUser);
    const [payType, setPayType] = useState('');
    const [date, setDate] = useState('Дата оплати');
    const [hours, setHours] = useState('');
    const [minutes, setMinutes] = useState('');
    const [discountType, setDiscountType] = useState('')
    const [isUrgently, setIsUrgently] = useState(false);
    const [popup, setPopup] = useState(null);
    const [status, setStatus] = useState(null);
    const [saveData, setSaveData] = useState('');
    const [percentInputValue, setPercentInputValue] = useState(0);
    const [UAHInputValue, setUAHInputValue] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [requiredPaymentFields, setRequiredPaymentFields] = useState({
        first_name: order?.customer?.first_name,
        last_name: order?.customer?.last_name,
        phone: order?.customer?.phone,
        productsAmount: order?.kit_count + order?.warehouse_item_count || 0
    });

    useEffect(() => {
        if (order && order.payment) {
            if (payType !== order.payment.type) {
                setPayType(order.payment.type);
            }
            if (isUrgently !== order.is_immediate) {
                setIsUrgently(order.is_immediate);
            }
            if (order.payment && order.payment.paid_time && date !== `${order.payment.paid_time.substring(8, 10)}.${order.payment.paid_time.substring(5, 7)}.${order.payment.paid_time.substring(0, 4)}`) {
                setDate(`${order.payment.paid_time.substring(8, 10)}.${order.payment.paid_time.substring(5, 7)}.${order.payment.paid_time.substring(0, 4)}`);
            }
            if (order.payment && order.payment.paid_time && hours !== order.payment.paid_time.substring(11, 13) && minutes !== order.payment.paid_time.substring(14, 16)) {
                setHours(order.payment.paid_time.substring(11, 13))
                setMinutes(order.payment.paid_time.substring(14, 16))

            }
            if (discountType !== order.payment.discount_type) {
                setDiscountType(order.payment.discount_type);
                if (order.payment.discount_type === 'VAL' && order.payment.discount_amount) {
                    setUAHInputValue(order.payment.discount_amount)
                } else if (order.payment.discount_type === 'PERC' && order.payment.discount_amount) {
                    setPercentInputValue(order.payment.discount_amount);
                }
            }
            if (status !== order.payment.status && order.payment.status !== 'NONE') {
                setStatus(order.payment.status);
            }
        }

    }, [order])

    const formValues = {
        userUrl: order.customer && order.customer.url ? order.customer.url : '',
        nickName: order.customer && order.customer.username ? order.customer.username : '',
        first_name: order.customer && order.customer.first_name ? order.customer.first_name : '',
        last_name: order.customer && order.customer.last_name ? order.customer.last_name : '',
        middle_name: order.customer && order.customer.middle_name ? order.customer.middle_name : '',
        phone: order.customer && order.customer.phone ? order.customer.phone : '',
        post_paid_amount: order.payment && order.payment.post_paid_amount ? order.payment.post_paid_amount : '',
        discount_amount: order.payment && order.payment.discount_amount ? order.payment.discount_amount : '',
        sum: order?.payment?.amount ? order.payment.amount : '',
        comment: order.comment ? order.comment : '',
        amountDue: '',
    }

    const {handleSubmit, handleChange, values, setValues, setFieldValue} = useFormik({
        initialValues: formValues,
    });

    const auditDates = (value, fieldName, type) => {
        const accessToken = getAccessToken();
        if (saveData !== value && type === 'customer') {
            const processedValue = fieldName === 'phone' ? value.replace(/[ ()-]/g, '') : value;
            updateCustomer(accessToken, order.id, {[fieldName]: processedValue}).then((res) => {
                setRequiredPaymentFields(prev => ({...prev, [fieldName]: res?.[fieldName]}));
            })
        } else if (saveData !== value && type === 'payment') {
            setIsLoading(true);
            updatePayment(accessToken, order.id, {[fieldName]: value}).then((res) => {
                if (fieldName === 'status' && res?.status) {
                    setStatus(res.status);
                }
            }).finally(() => {
                setIsLoading(false);
            })
        } else if (saveData !== value && type === 'order') {
            updateOrder(accessToken, order.id, manager.id, {[fieldName]: value}).then((res) => console.log(res))
        }
    }

    const calculateTotalSum = () => {
        if (!values.discount_amount) {
            setFieldValue("amountDue", String(values.sum));
            return;
        }

        let totalSum = 0;

        if (discountType === 'PERC') {
            totalSum = values.sum - ((values.sum * values.discount_amount) / 100);
        } else if (discountType === 'VAL') {
            totalSum = values.sum - values.discount_amount;
        }

        setFieldValue("amountDue", String(Math.ceil(totalSum)));
    }

    const handleURLChange = (e) => {
        if (e.target.value) {
            const nickname = e.target.value.match(/(?:https?:\/\/)?(?:www.)?(?:instagram)(?:.com\/)?([@a-zA-Z0-9-_]+)/);
            if (Array.isArray(nickname) && nickname[1]) {
                setFieldValue("nickName", nickname[1]);
            } else {
                setFieldValue("nickName", "");
            }
        } else {
            setFieldValue("nickName", "");
        }

        handleChange(e);
    }

    const updateData = (value, fieldName = null) => {
        const accessToken = getAccessToken();
        if (fieldName) {
            updateCustomer(accessToken, order?.id, { [fieldName]: value }).then((res) => console.log(res))
        } else {
            updateCustomer(accessToken, order?.id, value).then((res) => console.log(res))
        }
    }

    useEffect(() => {
        calculateTotalSum();
    }, [values.sum, discountType, percentInputValue, UAHInputValue]);

    const onSubmit = (value) => {
        auditDates(value, 'status', 'order')
    }

    const handleValidChange = (key, value) => {
        if (/[^a-zа-яґєії+\-' ]+/ig.test(value) && value !== '') {
            return;
        }
        setFieldValue(key, value);
    }

    const validationNumber = (value) => {
        const newValue = value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
        setFieldValue("post_paid_amount", newValue);
    }

    const handlePaste = async () => {
        try {
            const text = await navigator.clipboard.readText();
            setFieldValue("userUrl", text);
            const nickname = text.match(/(?:https?:\/\/)?(?:www.)?(?:instagram)(?:.com\/)?([@a-zA-Z0-9-_]+)/);
            if (Array.isArray(nickname) && nickname[1]) {
                setFieldValue("nickName", nickname[1]);
                updateData({
                    url: text,
                    username: nickname[1]
                })
            } else {
                setFieldValue("nickName", "");
                updateData({
                    url: text,
                    username: ''
                })
            }
        } catch (err) {
            console.error('Failed to read clipboard: ', err);
        }
    }

    return (
        <div className={styles.order}>
            <form onSubmit={handleSubmit}>
                <h2 className={styles.order__title}>Замовлення</h2>
                <div className={styles.order__content}>
                    <p className={styles.order__header}>
                        <span>{order.id}</span>
                        {order && order.created && (
                            <span>{`${order.created.substring(8, 10)}.${order.created.substring(5, 7)}.${order.created.substring(0, 4)}`}</span>
                        )}
                    </p>
                    <div className={styles.form}>
                        <div className={styles.form__userUrl}>
                            <input
                                placeholder={'User URL'}
                                className={`baseInput ${['PAY_WAIT', 'PAID'].includes(status) ? styles.disabled : ''}`}
                                id="userUrl"
                                name="userUrl"
                                type="text"
                                value={values.userUrl}
                                onChange={handleURLChange}
                                onFocus={(e) => setSaveData(e.target.value)}
                                onBlur={(e) => {
                                    const accessToken = getAccessToken();
                                    updateCustomer(accessToken, order.id, {
                                        url: e.target.value,
                                        username: values.nickName
                                    }).then((res) => console.log(res))
                                }}
                            />
                            <button
                                className={styles.pasteBtn}
                                type='button'
                                onClick={handlePaste}
                                disabled={['PAY_WAIT', 'PAID'].includes(status)}
                            >
                                <img src={paste} alt=""/>
                            </button>
                        </div>
                        <div>
                            <input
                                placeholder={'Нік'}
                                className={`baseInput ${['PAY_WAIT', 'PAID'].includes(status) ? styles.disabled : ''}`}
                                aria-label={'username'}
                                id="nickName"
                                name="nickName"
                                type="text"
                                onChange={(e) => {
                                    handleChange(e)
                                }}
                                onFocus={(e) => setSaveData(e.target.value)}
                                onBlur={(e) => auditDates(e.target.value, 'username', 'customer')}
                                value={values.nickName}
                            />
                        </div>
                        <div className={styles.form__name}>
                            <div className="baseInputContainer">
                            <span className="coloredLabel colored--first_name"></span>
                                <input
                                    placeholder={'Ім`я'}
                                    className={`baseInput ${['PAY_WAIT', 'PAID'].includes(status) ? styles.disabled : ''}`}
                                    aria-label={'first_name'}
                                    id={'first_name'}
                                    name="first_name"
                                    type="text"
                                    onChange={(e) => handleValidChange(e.target.name, e.target.value)}
                                    onFocus={(e) => setSaveData(e.target.value)}
                                    onBlur={(e) => auditDates(e.target.value, 'first_name', 'customer')}
                                    value={values.first_name}
                                />
                            </div>
                            <div className="baseInputContainer">
                            <span className="coloredLabel colored--last_name"></span>
                                <input
                                    placeholder={'Прізвище'}
                                    className={`baseInput ${['PAY_WAIT', 'PAID'].includes(status) ? styles.disabled : ''}`}
                                    aria-label={'last_name'}
                                    id="last_name"
                                    name="last_name"
                                    type="text"
                                    onChange={(e) => handleValidChange(e.target.name, e.target.value)}
                                    onFocus={(e) => setSaveData(e.target.value)}
                                    onBlur={(e) => auditDates(e.target.value, 'last_name', 'customer')}
                                    value={values.last_name}
                                />
                            </div>
                        </div>
                        <div>
                            <div className="baseInputContainer">
                            <span className="coloredLabel colored--middle_name"></span>
                                <input
                                    placeholder={'По батькові'}
                                    className={'baseInput'}
                                    aria-label={'middle_name'}
                                    id="middle_name"
                                    name="middle_name"
                                    type="text"
                                    onChange={(e) => handleValidChange(e.target.name, e.target.value)}
                                    onFocus={(e) => setSaveData(e.target.value)}
                                    onBlur={(e) => auditDates(e.target.value, 'middle_name', 'customer')}
                                    value={values.middle_name}
                                />
                            </div>
                        </div>
                        <div>
                            <div className="baseInputContainer">
                            <span className="coloredLabel colored--phone"></span>
                                <InputMask
                                    mask="+38 (099) 999-99-99"
                                    value={values.phone}
                                    onChange={(e) => handleChange(e)}
                                    onFocus={(e) => setSaveData(e.target.value)}
                                    onBlur={(e) => auditDates(e.target.value, 'phone', 'customer')}
                                >
                                    {(inputProps) => (
                                        <input
                                            {...inputProps}
                                            placeholder={"Телефон замовника"}
                                            className={`baseInput ${['PAY_WAIT', 'PAID'].includes(status) ? styles.disabled : ''}`}
                                            aria-label={"phone"}
                                            id="phone"
                                            name="phone"
                                        />
                                    )}
                                </InputMask>
                            </div>
                        </div>
                        <div>
                            <input
                                placeholder={'Сума передоплати (післяоплата)'}
                                className={'baseInput'}
                                id="post_paid_amount"
                                name="post_paid_amount"
                                type='text'
                                inputMode="decimal"
                                value={values.post_paid_amount}
                                onChange={(e) => {validationNumber(e.target.value)}}
                                onFocus={(e) => setSaveData(e.target.value)}
                                onBlur={(e) => auditDates(Number(e.target.value), 'post_paid_amount', 'payment')}
                            />
                        </div>
                        <PayCharger payType={payType} setPayType={setPayType} auditDates={auditDates}/>
                        <DateTimeField date={date} setDate={setDate} setPopup={setPopup} popup={popup}
                                       auditDates={auditDates} hours={hours} setHours={setHours} minutes={minutes} setMinutes={setMinutes}/>
                        <StatusPay
                            setPopup={setPopup}
                            popup={popup}
                            status={status}
                            setStatus={setStatus}
                            auditDates={auditDates}
                            isPaymentAllowed={Object.values(requiredPaymentFields).every((value) => !!value)}
                            isLoading={isLoading}
                        />
                        <TotalPrice
                            price={values.sum}
                            discountType={discountType}
                            discountValue={discountType === 'PERC' ? percentInputValue : UAHInputValue}
                        />
                        <DiscountField
                            percentInputValue={percentInputValue}
                            setPercentInputValue={setPercentInputValue}
                            UAHInputValue={UAHInputValue}
                            setUAHInputValue={setUAHInputValue}
                            discountType={discountType}
                            setDiscountType={setDiscountType}
                            popup={popup}
                            setPopup={setPopup}
                            setDiscountValue={(discountValue) => setFieldValue("discount_amount", discountValue)}
                            orderId={order?.id}
                            getAccessToken={getAccessToken}
                            updatePayment={updatePayment}
                            isDisabled={status === 'PAID' || status === 'PAY_WAIT'}
                        />
                        <UrgentlyField setIsUrgently={setIsUrgently} isUrgently={isUrgently} auditDates={auditDates}/>
                        <div>
                            <textarea
                                placeholder={'Коментар до замовлення'}
                                className={'baseInput'}
                                aria-label={'comment'}
                                id="comment"
                                name="comment"
                                value={values.comment}
                                onChange={(e) => {
                                    handleChange(e)
                                }}
                                onFocus={(e) => setSaveData(e.target.value)}
                                onBlur={(e) => auditDates(e.target.value, 'comment', 'order')}
                            />
                        </div>
                    </div>
                    <div className={styles.action}>
                        <button type={'submit'} className={'globalBtn'} onClick={() => onSubmit('PROD')}
                                disabled={values.first_name.length < 3 || values.last_name.length < 3 || values.phone.length < 9 || payType === '' || (payType === 'POSTPAID' && !values.post_paid_amount)}>
                            <span>На виробництво</span>
                        </button>
                    </div>
                </div>
            </form>
            <Parser
                values={values}
                setValues={(newValues) => setValues(prevValues => ({ ...prevValues, ...newValues }))}
                updateData={updateData}
            />
        </div>
    )
}

export default OrderCreate;