import { baseUrl } from "./apiConfig";
import { refreshAccessToken } from "./authApi";

export const updatePayment = async (token, orderId, value) => {
    try {
        const response = await fetch(`${baseUrl}payments/` , {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                order_id: orderId,
                ...value,
            })
        });
        if (response && response.statusText === 'Unauthorized') {
            return refreshAccessToken().then((res) => {
                if (res) {
                    return updatePayment(res.access, orderId, value).then((result) => {
                        return result;
                    });
                }
            });
        } else {
            return response.json();
        }

    } catch (error) {
        console.log(error);
    }
}

export const sendPayMonobank = async (id) => {
    try {
        const response = await fetch(`${baseUrl}payments_types/monobank/` , {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                payment_id: id,
            })
        });
        return response.json();

    } catch (error) {
        console.log(error);
    }
}

export const sendPayWayForPay = async (id) => {
    try {
        const response = await fetch(`${baseUrl}payments_types/wayforpay/` , {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                payment_id: id,
            })
        });
        return response.json();

    } catch (error) {
        console.log(error);
    }
}

export const setFinishOrder = async (id, paymentType) => {
    try {
        const response = await fetch(`${baseUrl}payments_types/${paymentType}/${id}/successful/` , {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                payment_id: id,
            })
        });
        return response.json();

    } catch (error) {
        console.log(error);
    }
}