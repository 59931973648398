import React from "react";
import styles from './OrderActions.module.scss';
import copyIcon from "../../../assets/icons/copy2.svg";
import {CopyToClipboard} from "react-copy-to-clipboard";

const OrderActions = ({orderUrl, isDisabled, receiptUrl, onCopyAll}) => {
    return (
        <div className={styles.orderActions}>
            <div className={styles.row}>
                <button onClick={onCopyAll} className={`${styles.copyButton} click-effect`}>
                    <img src={copyIcon} alt=""/>
                    <span>Скопіювати все</span>
                </button>
                <a href={receiptUrl} target="_blank" className={`${styles.checkLink} ${!receiptUrl ? styles.disabled : ''}`}>
                    Посилання на чек
                </a>
            </div>
            <div className={styles.row}>
                {orderUrl && (
                    <CopyToClipboard text={orderUrl} className={`${styles.orderLink} click-effect`}>
                        <button>
                            <span>Посилання на замовлення</span>
                        </button>
                    </CopyToClipboard>
                )}
                <button className={styles.prodLink} disabled={isDisabled}>
                    <span>На виробництво</span>
                </button>
            </div>
        </div>
    )
}

export default OrderActions;