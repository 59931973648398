import React, {useEffect, useRef, useState} from "react";
import styles from '../FabricSet.module.scss';
import OptionButton from "../OptionButton/OptionButton";
import CommentIcon from "../../../../../assets/icons/comment.svg";
import OptionIcon from "../../../../../assets/icons/options.svg";
import ReturnIcon from "../../../../../assets/icons/return.svg";
import EditSet from "../../../../../assets/icons/editSet.svg";
import DeleteSet from "../../../../../assets/icons/bin.svg";
import CommentPopup from "../../Popups/CommentPopup/CommentPopup";
import closeIcon from "../../../../../assets/icons/closeCross.svg";
import FabricSetItemSize from "./FabricSetItemSize/FabricSetItemSize";
import FabricSetItemType from "./FabricSetItemType/FabricSetItemType";
import FabricSetItemOptions from "./FabricSetItemOptions/FabricSetItemOptions";
import CustomSizePopup from "../../Popups/CustomSizePopup/CustomSizePopup";
import OptionPopup from "../../Popups/OptionPopup/OptionPopup";
import { useAppDispatch } from "../../../../../hooks/redux";
import { setIsActivePopup } from "../../../../../store/main-slice";

const FabricSetItem = ({
    inPopup,
    item,
    types,
    componentIndex,
    onChangeOptions,
    form,
    onDelete = () => {},
    isEdit,
    componentSize,
    onSelectSize,
    isKitSplitted = false
}) => {
    const name = componentSize ? componentSize?.sizes[componentSize?.selected]?.name : item?.name || 'Невідома назва';
    const price = componentSize ? componentSize?.sizes[componentSize?.selected]?.price_retail : item?.price_retail || 0;
    const image = componentSize ? componentSize?.sizes[componentSize?.selected]?.image : item?.image?.length > 0 ? item?.image[0] : item?.image || '';

    const [containerPosition, setContainerPosition] = useState(2);
    const [touchStartX, setTouchStartX] = useState(0);
    const [touchEndX, setTouchEndX] = useState(0);
    const containerRef = useRef(null);
    const leftHiddenLinkRef = useRef(null);
    const rightHiddenLinkRef = useRef(null);

    const [showCommentPopup, setShowCommentPopup] = useState(false);
    const [showCustomSizePopup, setShowCustomSizePopup] = useState(false);
    const [showOptionPopup, setShowOptionPopup] = useState(false);

    const [chosenOptions, setChosenOptions] = useState([]);
    const dispatch = useAppDispatch();

    const handleReturn = () => {
        setContainerPosition(2);
        console.log('return')
    };

    const handleCommentPopup = () => {
        setContainerPosition(2);
        setShowCommentPopup(true);
        dispatch(setIsActivePopup(true));
    }

    const closeCommentPopup = () => {
        setShowCommentPopup(false);
        dispatch(setIsActivePopup(false));
    }

    const handleCustomSizePopup = () => {
        setContainerPosition(2);
        setShowCustomSizePopup(true);
        dispatch(setIsActivePopup(true));
    }

    const closeCustomSizePopup = () => {
        setShowCustomSizePopup(false);
        dispatch(setIsActivePopup(false));
    }

    const handleOptionPopup = () => {
        setContainerPosition(2);
        setShowOptionPopup(true);
        dispatch(setIsActivePopup(true));
    }

    const closeOptionPopup = () => {
        setShowOptionPopup(false);
        dispatch(setIsActivePopup(false));
    }

    const handleTouchStart = (e) => {
        setTouchStartX(e.targetTouches[0].clientX);
        setTouchEndX(e.targetTouches[0].clientX);
    };

    const handleTouchMove = (e) => {
        setTouchEndX(e.targetTouches[0].clientX);
    };

    const handleTouchEnd = () => {
        if (touchStartX - touchEndX < -50 && containerPosition > 1) {
            setContainerPosition(containerPosition - 1);
        } else if (touchStartX - touchEndX > 50 && containerPosition < 3) {
            setContainerPosition(containerPosition + 1);
        }
    };

    const handleSelectSize = (sizeIndex) => {
        onSelectSize(componentIndex, sizeIndex);
    }

    const handleDeleteComponent = (itemId) => {
        setContainerPosition(2);
        onDelete(componentIndex, itemId);
    }

    useEffect(() => {
        if (isEdit) {
            let selectedOptionArr = [];
            let optionsInfo = item?.options || [];
            selectedOptionArr = optionsInfo.filter(el => el?.selected).map((el) => {
                return {
                    name: el.name,
                    id: el.id
                }
            });
            if (selectedOptionArr.length) {
                setChosenOptions(selectedOptionArr);
            }
        }
    }, [item]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (containerRef.current &&
                !containerRef.current.contains(event.target) &&
                !leftHiddenLinkRef.current.contains(event.target) &&
                !rightHiddenLinkRef.current.contains(event.target)) {
                setContainerPosition(2);
            }
        };

        document.addEventListener('touchstart', handleClickOutside);

        return () => {
            document.removeEventListener('touchstart', handleClickOutside);
        };
    }, [containerRef]);

    return (
        <div className={styles.wrapper}>
            <div className={styles.content}
                 style={{left: containerPosition === 1 ? '0' : containerPosition === 3 ? '-92px' : '-46px'}}
                 onTouchStart={handleTouchStart}
                 onTouchMove={handleTouchMove}
                 onTouchEnd={handleTouchEnd} ref={containerRef}>
                <button onClick={handleCustomSizePopup} className={styles.hiddenLeftBtn} ref={leftHiddenLinkRef}>
                    <img src={EditSet} alt=""/>
                </button>
                <div className={`${styles.setWrap} ${inPopup ? styles.inPopup : ""}`}>
                    <div className={styles.set}>
                        <div className={styles.mainRow}>
                            <div className={styles.set__color} onClick={() => setContainerPosition(2)}>
                                <img className={styles.colorCover} src={image} alt=""/>
                            </div>
                            <div className={styles.set__panel}>
                                <div className={`${styles.grid} ${styles.titleRow}`}>
                                    <p className={styles.name}>{name}:</p>
                                    <p className={styles.price}>{price} грн</p>
                                    <OptionButton img={CommentIcon}
                                                  action={!inPopup ? handleCommentPopup : () => setContainerPosition(2)}/>
                                </div>
                                <div className={`${styles.row} ${styles.featureRow}`}>
                                    <div className={styles.sizesWrapper}>
                                        {componentSize?.sizes?.map((item, idx) => (
                                            <FabricSetItemSize
                                                key={idx}
                                                item={item}
                                                isActive={componentSize.selected === idx}
                                                setContainerPosition={setContainerPosition}
                                                onSelectSize={() => handleSelectSize(idx)}
                                            />
                                        ))}
                                    </div>
                                    <FabricSetItemType item={item} types={types}
                                                       setContainerPosition={setContainerPosition}/>
                                </div>
                            </div>

                        </div>
                        <div className={styles.optionRow}>
                            <FabricSetItemOptions item={item} chosenOptions={chosenOptions}
                                                  setChosenOptions={setChosenOptions} componentIndex={componentIndex}
                                                  onChangeOptions={onChangeOptions}
                                                  setContainerPosition={setContainerPosition} isEdit={isEdit}/>
                            {item?.options?.length > 1 && <OptionButton img={OptionIcon}
                                                                       action={!inPopup ? handleOptionPopup : () => setContainerPosition(2)}/>}
                        </div>
                    </div>
                    {!inPopup && <button className={styles.returnButton} onClick={() => handleReturn()}>
                        <img src={ReturnIcon} alt=""/>
                    </button>}
                </div>
                <button onClick={() => handleDeleteComponent(item?.id)} className={styles.hiddenRightBtn}
                        ref={rightHiddenLinkRef} disabled={!isKitSplitted && item?.is_base}>
                    <img src={DeleteSet} alt=""/>
                </button>
            </div>

            {showCommentPopup && (
                <div
                    className={`${showCommentPopup ? `${styles.globalPopupActive} ${styles.topPopup}` : styles.globalPopup}`}
                    onClick={(e) => e.stopPropagation()}>
                    <div className={styles.header}>
                        <p className={styles.header__title}>Коментар</p>
                        <div className={styles.header__close} onClick={() => closeCommentPopup()}>
                            <span>Закрити</span>
                            <button>
                                <img src={closeIcon} alt=""/>
                            </button>
                        </div>
                    </div>
                    <div className={styles.globalPopup__content}>
                        <CommentPopup
                            componentIndex={componentIndex}
                            onChangeOptions={onChangeOptions}
                            form={form}
                            close={closeCommentPopup}
                            isEdit={isEdit}
                            item={item}
                        />
                    </div>
                </div>
            )}
            {showCustomSizePopup && (
                <CustomSizePopup
                    show={showCustomSizePopup}
                    close={closeCustomSizePopup}                                
                    onChangeOptions={onChangeOptions}    
                    componentIndex={componentIndex}
                    form={form}
                />
            )}
            {showOptionPopup && (
                <OptionPopup
                    show={showOptionPopup}
                    close={closeOptionPopup}
                    chosenOptions={chosenOptions}
                    setChosenOptions={setChosenOptions}
                    item={item}
                    onChangeOptions={onChangeOptions}
                    componentIndex={componentIndex}
                    form={form}
                />
            )}
        </div>
    )
}

export default FabricSetItem