import React, {useEffect, useState} from "react";
import styles from './Fabric.module.scss';
import ImgPlaceholder from '../../assets/img/FabricImgPlaceholder.png';
import FabricSlider from "./FabricSlider";
import {useLocation} from "react-router";
import queryString from "query-string";
import Preloader from "../Common/Preloader/Preloader";
import { getFabricItem, getProductDetail } from "../../api/productsApi";

const Fabric = () => {
    const params = useLocation();
    const urlString = params.search;
    const hash = queryString.parse(urlString);
    const [fabric, setFabric] = useState(null);
    const [product, setProduct] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const item = {
        name: fabric?.name ? fabric.name : product?.name ? product.name : 'Невідома назва',
        type: fabric?.type?.type ? fabric.type.type :  product?.type?.type ? product.type.type : 'Невідомий тип',
        images: fabric?.images ? fabric.images : product?.images ? product.images : []
    }

    useEffect(() => {
        if (urlString && hash?.id) {
            getFabricItem(hash.id).then((response) => {
                if (response) {
                    setFabric(response)
                }
            }).finally(() => {
                setIsLoading(false);
            })
        } else if (urlString && hash?.storageId) {
            getProductDetail(hash.storageId).then((result) => {
                if (result) {
                    setProduct(result)
                }
            }).finally(() => {
                setIsLoading(false);
            })
        }
    }, []);

    if (isLoading) {
        return <Preloader/>
    }

    return (
        <div className={styles.fabric}>
            {item.images.length > 1 ? <FabricSlider images={item.images}/> : <div className={styles.imgPlaceholder}>
                <img src={!item.images.length || !item.images[0] ? ImgPlaceholder : item.images[0]}
                     className={!item.images.length || !item.images[0] ? '' : styles.mainImg}
                     alt=""/>
            </div>}
            <div className={item.images.length > 1 ? `${styles.tableWrap} ${styles.withSlider}` : styles.tableWrap}>
                <h3>{item.name} {fabric && item.type}</h3>
                <div className={styles.table}>
                    <div><span>{product ? 'Колір' : 'Назва'}</span></div>
                    <div><span>Вартість</span></div>
                    <div><span>Доступно(шт).</span></div>
                    {product?.sizes?.map((size) =>
                        size.colors.map((color) => (
                            <React.Fragment key={color.color_id}>
                                <div><span>{color.name}</span></div>
                                <div><span>{color.price}</span></div>
                                <div><span>{color.quantity}</span></div>
                            </React.Fragment>
                        ))
                    )}
                    {fabric?.available_kit_templates?.length > 0 && fabric?.available_kit_templates.map((template, idx) => (
                        <React.Fragment key={idx}>
                            <div><span>{template.name}</span></div>
                            <div><span>{template.price}</span></div>
                            <div><span>{template.count}</span></div>
                        </React.Fragment>
                    ))}
                </div>
                {fabric && <p>Доступно в компанії</p>}
            </div>
        </div>
    )
}

export default Fabric;