import React, {useEffect, useState} from "react";
import styles from './FabricType.module.scss';
import FabricTypeFabrics from "./FabricTypeFabrics/FabricTypeFabrics";
import FabricArticles from "./FabricArticles/FabricArticles";
import { useNavigate } from "react-router-dom";
import { getAccessToken } from "../../../../utils/authTokens";
import { createProduct } from "../../../../api/calculatorApi";

const FabricType = ({
    setTemplate,
    fabric,
    setFabric,
    setComponents,
    id,
    setProduct,
    setKitCommentText,
    setForm,
    isEdit,
    isLoading,
    setIsLoading,
    setFabricConsumption,
    setComponentSizes,
    setIsKitSplitted,
    onFabricTypeChange
}) => {
    const [fabrics, setFabrics] = useState(null);
    const navigate = useNavigate();

    const setObject = (value, isEdit = false) => {
        if (value && value.kit && value.kit.length > 0 && isEdit) {
            setComponents(value.kit[0]);
            const componentSizes = value.kit[0].components.map(component => ({
                sizes: [{
                    id: component.id,
                    name: component.name,
                    price_retail: component.price_retail,
                    size: component.size.name,
                    image: component.image
                }],
                selected: 0
            }));
            setComponentSizes(componentSizes);
            setFabricConsumption(Math.round(value.kit[0]?.additional_fabric_consumption_count * 100) ?? 0);
            setFabric(value.kit[0].fabric);
            setIsKitSplitted(value.kit[0].is_split);
        }
        setTemplate(value);
        const newArray = value.fabric_types.map((item) => {
            return (
                {
                    name: item.type,
                    img: item.image ? item.image : '',
                    id: item.id,
                }
            )
        });
        setFabrics(newArray);
    }

    useEffect(() => {
        const accessToken = getAccessToken();
        setIsLoading(true);
        if (id) {
            createProduct(accessToken, id).then((resp) => {
                if (resp?.detail) {
                    navigate('/');
                    return;
                }
                setObject(resp, true);
                setProduct({
                    kit: resp.kit,
                    kit_comment: resp.kit_comment,
                    kit_options: resp.kit_options,
                })
                setKitCommentText(resp.kit_comment);
                setForm({
                    order_id: Number(id),
                    fabric_id: resp.kit[0]?.fabric?.id,
                    template_components: resp.kit[0].components.map((item) => {
                        let optionsInitialInfo = item?.options || [];
                        let formattedOptions = [];
                        formattedOptions = optionsInitialInfo.filter(el => el?.selected).map((el) => {
                            return el.id
                        });
                        return {
                            kit_template_id: resp.kit[0]?.template_size?.id,
                            component_template_id: item?.id,
                            option: formattedOptions,
                            comment: item?.comment,
                            custom_size: item?.custom_size
                        }
                    }),
                    additional_components: [],
                    custom_size: "",
                    option: [],
                });
            }).finally(() => {
                setIsLoading(false);
            })
        } else {
            createProduct(accessToken).then((result) => {
                setObject(result)
            }).finally(() => {
                setIsLoading(false);
            })
        }
    }, [])

    return (
        <div className={styles.type}>
            <h3>Пара тканин</h3>
            <FabricArticles fabric={fabric}/>
            {fabrics && (
                <FabricTypeFabrics
                    fabricTypes={fabrics}
                    setFabric={setFabric}
                    isEdit={isEdit}
                    fabric={fabric}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    onFabricTypeChange={onFabricTypeChange}
                />
            )}
        </div>
    )
}

export default FabricType