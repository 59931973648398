import React, {useState} from "react";
import styles from './FabricTypeFabrics.module.scss';
import FabricTypeItem from "./FabricTypeItem";
import {useAppDispatch} from "../../../../../hooks/redux";
import {setIsActivePopup} from "../../../../../store/main-slice";
import GlobalPopup from "../../../../Common/GlobalPopup/GlobalPopup";
import FabricTypePopup from "../FabricTypePopup/FabricTypePopup";
import { getAccessToken } from "../../../../../utils/authTokens";
import { getFabrics } from "../../../../../api/productsApi"

const FabricTypeFabrics = ({fabricTypes, setFabric, isEdit, fabric, onFabricTypeChange}) => {
    const [list, setList] = useState(null);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [searchValue, setSearchValue] = useState('');
    const [fabricId, setFabricID] = useState(null);
    const [showFabricPopup, setShowFabricPopup] = useState(false);
    const [chosenFabric, setChosenFabric] = useState('');
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(false);

    const handlePaginationChange = (event, value) => {
        if (page !== value) {
            setIsLoading(true);
            setPage(value);
            window.scrollTo({top: 0, behavior: 'auto'});
            const accessToken = getAccessToken();
            if (searchValue.length > 0) {
                getFabrics(accessToken, value, [fabricId], searchValue).then((response) => {
                    if (response?.fabrics) {
                        setList(response.fabrics);
                    }
                    if (response?.total_pages) {
                        setTotalPages(response.total_pages)
                    }
                    setIsLoading(false);
                })
            } else {
                getFabrics(accessToken, value, [fabricId]).then((response) => {
                    if (response?.fabrics) {
                        setList(response.fabrics);
                    }
                    if (response?.total_pages) {
                        setTotalPages(response.total_pages)
                    }
                    setIsLoading(false);
                }).catch(err => {
                    console.error("Error fetching fabrics on page change: ", err);
                })
            }
        }
    };

    const openFabricPopup = (name) => {
        setShowFabricPopup(true);
        setChosenFabric(name);
        dispatch(setIsActivePopup(true));
    }

    const closeFabricPopup = () => {
        dispatch(setIsActivePopup(false));
        setShowFabricPopup(false);
        setChosenFabric('');
        setList(null);
        setSearchValue('')
    }

    const onSendFilters = (id) => {
        const accessToken = getAccessToken();
        getFabrics(accessToken, 1, [id]).then((response) => {
            if (response?.fabrics) {
                setList(response.fabrics);
            }
            if (response?.total_pages) {
                setTotalPages(response.total_pages)
            }
        })
    }

    return (
        <div className={styles.fabric}>
            <div className={styles.fabric__row}>
                {!isEdit? fabricTypes.map((item) => <FabricTypeItem key={item.name} item={item} onClick={() => {
                    openFabricPopup(item.name);
                    onSendFilters(item.id);
                    setFabricID([item.id]);
                }}/>) : fabricTypes.filter(el => el.id === fabric?.type_id)
                    .map((item) => <FabricTypeItem key={item.name} item={item} disabled/>)}
            </div>
            {showFabricPopup && fabricId && (
                <GlobalPopup topPopup title={`${chosenFabric}:`} onClose={() => closeFabricPopup()}>
                    <FabricTypePopup searchValue={searchValue} setSearchValue={setSearchValue} page={page} setList={setList}
                                     setPage={setPage} setTotalPages={setTotalPages} filter={fabricId} list={list}
                                     handlePaginationChange={handlePaginationChange} totalPages={totalPages} setFabric={setFabric}
                                     closeFabricPopup={closeFabricPopup} isLoading={isLoading} setIsLoading={setIsLoading}
                                     onFabricTypeChange={onFabricTypeChange}/>
                </GlobalPopup>
            )}
        </div>
    )
}

export default FabricTypeFabrics