import React, {useEffect, useRef} from "react";
import searchIcon from "../../../assets/icons/search.svg";
import {setFabricsList} from "../../../store/main-slice";
import {useAppDispatch} from "../../../hooks/redux";
import styles from './SearchFabrics.module.scss';
import { getAccessToken } from "../../../utils/authTokens";
import { getFabrics } from "../../../api/productsApi";


const SearchFabrics = ({
    searchValue,
    setSearchValue,
    setPage,
    setTotalPages,
    filter,
    isCalculator = false,
    setList = null,
    setIsLoading
}) => {
    const dispatch = useAppDispatch();
    const timeout = useRef(null);

    const sendSearch = (page, filter, search) => {
        const accessToken = getAccessToken();
        setIsLoading(true);
        getFabrics(accessToken, page, filter, search).then((response) => {
            if (response?.fabrics) {
                isCalculator ? setList(response.fabrics) : dispatch(setFabricsList(response.fabrics));
            }
            if (response?.total_pages) {
                setTotalPages(response.total_pages)
            }
        }).catch(err => {
            console.error("Error fetching fabrics on page change: ", err);
        }).finally(() => {
            setIsLoading(false);
        })
    }

    useEffect(() => {
        if (searchValue.length > 0) {
            clearTimeout(timeout.current);

            timeout.current = setTimeout(() => {
                sendSearch(1, filter, searchValue);
            }, 500);
            return () => {
                clearTimeout(timeout.current);
            };
        } else if (searchValue === '') {
            setPage(1);
            sendSearch(1, filter);
        }
    }, [searchValue]);

    return (
        <div className={styles.fabrics__search}>
            <input type="search" placeholder={'Пошук'} className={'baseInput'} value={searchValue}
                   onChange={(e) => setSearchValue(e.target.value)}/>
            <img src={searchIcon} alt=""/>
        </div>
    )
}

export default SearchFabrics;