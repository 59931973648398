import React, {useEffect, useMemo, useState} from "react";
import styles from './Products.module.scss';
import ProductActions from "./ProductActions/ProductActions";
import DiscountField from "../OrderCreate/DiscountField";
import CartProducts from "./CartProducts/CartProducts";
import TotalPrice from "./TotalPrice/TotalPrice";
import Preloader from "../../Common/Preloader/Preloader";
import {setIsNotFound} from "../../../store/main-slice";
import { useParams } from "react-router-dom";
import NotFound from "../../Common/NotFound/NotFound";
import { useAppDispatch } from "../../../hooks/redux";
import { getAccessToken } from "../../../utils/authTokens";
import { getOrderInfo } from "../../../api/ordersApi";
import { getCartOrder } from "../../../api/productsApi";
import { updatePayment } from "../../../api/paymentApi";

const Products = () => {
    const {id} = useParams();
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const [order, setOrder] = useState(null);
    const [warehouseItems, setWarehouseItems] = useState([]);
    const [discountType, setDiscountType] = useState('');
    const [percentInputValue, setPercentInputValue] = useState(0);
    const [UAHInputValue, setUAHInputValue] = useState(0);
    const [popup, setPopup] = useState(null);
    const isDisabled = order?.payment?.status === 'PAID' || order?.payment?.status === 'PAY_WAIT';

    const totalPrice = useMemo(() => {
        if (order?.id && warehouseItems.length > 0) {
            const accessToken = getAccessToken();
            const result = warehouseItems.reduce((sum, {warehouse_item, kit}) => sum + (warehouse_item ? warehouse_item.price : kit.price_retail), 0);
            updatePayment(accessToken, order.id, {'amount': result}).then((res) => console.log(res))
            return result;
        }
        return null;
    }, [order?.id, warehouseItems])

    const handleSaveFinalPrice = (finalPrice) => {
        const accessToken = getAccessToken();
        updatePayment(accessToken, order.id, {'final_amount': finalPrice}).then((res) => console.log(res))
    }

    useEffect(() => {
        let accessToken = getAccessToken();
        getOrderInfo(accessToken, id).then((response) => {
            if (response && response.id) {
                setOrder(response);
                setIsLoading(true);
                accessToken = getAccessToken();
                getCartOrder(accessToken, id).then((response) => {
                    if (response && response.length > 0) {
                        setWarehouseItems(response);
                    }
                }).finally(() => {
                    setIsLoading(false);
                })
            } else {
                setIsLoading(false);
                dispatch(setIsNotFound(true));
            }
        }).catch(err => {
            console.log(err);
            setIsLoading(false);
        })
    }, [])

    useEffect(() => {
        if (order?.payment) {
            const {discount_type, discount_amount} = order.payment;
            if (discountType !== discount_type) {
                setDiscountType(discount_type);
                if (discount_type === 'VAL' && discount_amount) {
                    setUAHInputValue(discount_amount)
                } else if (discount_type === 'PERC' && discount_amount) {
                    setPercentInputValue(discount_amount);
                }
            }
        }
    }, [order])

    const handleOrderPayment = (payment) => {
        setOrder(prevOrder => ({ ...prevOrder, payment: payment }));
    }

    return (
        <>
            {!isLoading && order && (
                <div className={styles.products}>
                    <h2 className={styles.products__title}>Товар</h2>
                    {warehouseItems && (
                        <CartProducts
                            items={warehouseItems} 
                            setItems={setWarehouseItems} 
                            setOrderPayment={handleOrderPayment}
                            isDisabled={isDisabled}
                        />
                    )}
                    <ProductActions
                        order={order}
                        popup={popup}
                        setPopup={setPopup}
                        setOrder={setWarehouseItems}
                        isDisabled={isDisabled}
                    />
                    <TotalPrice
                        price={totalPrice || order?.payment?.amount}
                        discountType={discountType}
                        discountValue={discountType === 'PERC' ? percentInputValue : UAHInputValue}
                        setFinalPrice={handleSaveFinalPrice}
                    />
                    <DiscountField
                        percentInputValue={percentInputValue}
                        setPercentInputValue={setPercentInputValue}
                        UAHInputValue={UAHInputValue}
                        setUAHInputValue={setUAHInputValue}
                        discountType={discountType}
                        setDiscountType={setDiscountType}
                        popup={popup}
                        setPopup={setPopup}
                        orderId={order?.id}
                        getAccessToken={getAccessToken}
                        updatePayment={updatePayment}
                        isDisabled={isDisabled}
                    />
                    {isLoading && (
                        <Preloader />
                    )}
                </div>
            )}
            {isLoading && <Preloader />}
            {!isLoading && !order && (
                <NotFound message={`Замовлення №${id} не знайдено`} />
            )}
        </>
    )
}

export default Products;