import React, {useEffect, useState} from "react";
import styles from './Storage.module.scss';
import StorageItem from "../../Common/StorageItem/StorageItem";
import {Pagination} from "@mui/material";
import Preloader from "../../Common/Preloader/Preloader";
import SearchStorage from "../../Common/SearchStorage/SearchStorage";
import { getStorageProducts } from "../../../api/productsApi";
import { getAccessToken } from "../../../utils/authTokens";

const Storage = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [list, setList] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [searchValue, setSearchValue] = useState('');

    useEffect(() => {
        if (list && list.length === 0) {
            setIsLoading(true);
            const accessToken = getAccessToken();
            getStorageProducts(accessToken, 1).then((res) => {
                if (res?.items && res.items.length > 0) {
                    setList(res.items);
                    setIsLoading(false);
                }
                if (res?.total_pages) {
                    setTotalPages(res.total_pages);
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            })
        }
    }, [])

    const handlePaginationChange = (event, value) => {
        if (page !== value) {
            setIsLoading(true);
            setPage(value);
            window.scrollTo({top: 0, behavior: 'auto'});
            const accessToken = getAccessToken();
            getStorageProducts(accessToken, value).then((res) => {
                if (res?.items && res.items.length > 0) {
                    setList(res.items);
                    setIsLoading(false);
                }}).catch(err => {
                setIsLoading(false);
                console.error("Error fetching storage items on a page change", err);
            })
        }
    }

    return (
        <div className={styles.storage}>
            <div className={styles.storage__search}>
                <SearchStorage searchValue={searchValue} setSearchValue={setSearchValue} setPage={setPage}
                               setTotalPages={setTotalPages} setStorageList={setList}/>
            </div>
            {list && list.length > 0 &&
                list.map(({id, name, images, sizes}) => <StorageItem key={id} id={id} name={name} images={images} sizes={sizes} copy/>)
            }
            {totalPages > 1 && (
                <Pagination className={styles.pagination} count={totalPages} page={page} siblingCount={1}
                            boundaryCount={1} hidePrevButton hideNextButton onChange={handlePaginationChange}/>
            )}
            {isLoading && (
                <Preloader />
            )}
        </div>
    )
}

export default Storage;