import React, {useEffect, useRef} from "react";
import styles from './SearchStorage.module.scss';
import searchIcon from "../../../assets/icons/search.svg";
import { getAccessToken } from "../../../utils/authTokens";
import { getSearchResult } from "../../../api/searchApi";
import { getStorageProducts } from "../../../api/productsApi";

const SearchStorage = ({searchValue, setSearchValue, setPage, setTotalPages, setStorageList}) => {
    const timeout = useRef(null);

    const sendSearch = (value = null, page) => {
        const accessToken = getAccessToken();
        getSearchResult(accessToken, searchValue,  true, true, true, page).then((resp) => {
            if (resp && resp.warehouse_items && resp.warehouse_items.items && resp.warehouse_items.items.length > 0) {
                setStorageList(resp.warehouse_items.items);
                setPage(resp.warehouse_items.current_page);
                setTotalPages(resp.warehouse_items.total_pages)
            } else {
                console.log(resp)
            }
        })
    }

    useEffect(() => {
        if (searchValue.length > 0) {
            clearTimeout(timeout.current);
            timeout.current = setTimeout(() => {
                sendSearch(null, 1);
            }, 500);
            return () => {
                clearTimeout(timeout.current);
            };
        } else if (searchValue === '') {
            const accessToken = getAccessToken();
            getStorageProducts(accessToken, 1).then((res) => {
                if (res?.items && res.items.length > 0) {
                    setStorageList(res.items)
                }
                if (res?.total_pages) {
                    setTotalPages(res.total_pages)
                }
            })
        }
    }, [searchValue]);

    return (
        <div className={styles.searchStorage}>
            <input type="search" placeholder={'Пошук'} className={'baseInput'} value={searchValue}
                   onChange={(e) => setSearchValue(e.target.value)}/>
            <img src={searchIcon} alt=""/>
        </div>
    )
}

export default SearchStorage;