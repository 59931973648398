import { useEffect, useRef } from "react";
import searchIcon from "../../../../../assets/icons/search.svg";
import styles from "./ComponentsPopup.module.scss";
import { getKitCustomComponents } from "../../../../../api/calculatorApi";
import { getAccessToken } from "../../../../../utils/authTokens";

export default function ComponentsSearch({ searchValue, setSearchValue, selectedFiltersIds, setPage, setTotalPages, setComponents }) {
    const timeout = useRef(null);

    const sendSearch = (page, selectedFiltersIds, search) => {
        const accessToken = getAccessToken();
        getKitCustomComponents(accessToken, page, selectedFiltersIds, search).then((response) => {
            if (response?.custom_components) {
                setComponents(response.custom_components);
            }
            if (response?.total_pages) {
                setTotalPages(response.total_pages);
            }
        }).catch(err => {
            console.error("Error fetching fabrics on page change: ", err);
        })
    }

    useEffect(() => {
        if (searchValue.length > 0) {
            clearTimeout(timeout.current);

            timeout.current = setTimeout(() => {
                sendSearch(1, selectedFiltersIds, searchValue);
            }, 500);
            return () => {
                clearTimeout(timeout.current);
            };
        } else if (searchValue === '') {
            setPage(1);
            sendSearch(1, selectedFiltersIds);
        }
    }, [searchValue]);

    return (
        <div className={styles.components__search}>
            <input type="search" placeholder={'Пошук'} className={'baseInput'} value={searchValue}
                   onChange={(e) => setSearchValue(e.target.value)}/>
            <img src={searchIcon} alt=""/>
        </div>
    )
}
