import { baseUrl } from "./apiConfig";

export const getReceipt = async (paymentId) => {
    try {
        const response = await fetch(`${baseUrl}receipt/?payment=${paymentId}` , {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
        return response.json();
    } catch (error) {
        console.log(error);
    }
}

export const setCheckboxReceipt = async (payment_id, payment_type) => {
    try {
        const response = await fetch(`${baseUrl}/checkbox/receipt/`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                payment_id: payment_id,
                payment_type: payment_type
            })
        });
        return response.json();
    } catch (error) {
        console.log(error);
    }
}

export const setReceipt = async (formData) => {
    try {
        const response = await fetch(`${baseUrl}receipt/`, {
            method: "POST",
            body: formData
        });
        return response.json();
    } catch (error) {
        console.log(error);
    }
}
